<template>
  <div class="pageContol listWrap templateList promoteBox">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">推广设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox">
            <div title="轮播图名称" class="searchboxItem ci-full rotationName">
              <span class="itemLabel">轮播图名称:</span>
              <el-input
                v-model="rotationName"
                type="text"
                size="small"
                placeholder="请输入轮播图名称"
                clearable
              />
            </div>
            <div title="行政区域" class="searchboxItem ci-full">
              <span class="itemLabel">行政区域:</span>
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                clearable
                filterable
                placeholder="请选择行政区划"
                size="small"
              ></el-cascader>
            </div>
            <div title="轮播图状态" class="searchboxItem ci-full state">
              <span class="itemLabel">轮播图状态:</span>
              <el-select v-model="state" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in bannerStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top:10px">
            <div title="发布系统" class="searchboxItem ci-full terminalsSet">
              <span class="itemLabel" style="min-width:6rem">发布系统:</span>
               <el-select v-model="terminal" placeholder="请选择发布系统" size="small" clearable @change="terminalsChange">
                <el-option
                  v-for="item in PublishingSystemList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="应用端" class="searchboxItem ci-full terminalsSet">
              <span class="itemLabel" >应用端:</span>
               <el-select v-model="rotationPositionId" placeholder="请选择发布系统" size="small" clearable>
                <el-option
                  v-for="item in sysRotationPositionData"
                  :key="item.rotationPositionId"
                  :label="item.rotationPositionName"
                  :value="item.rotationPositionId"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="add('add')">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :span-method="objectSpanMethod"
              :header-cell-style="tableHeader"
              :cell-style="cellbgc"
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100"/>
              <el-table-column
                label="轮播图名称"
                align="left"
                prop="rotationName"
                show-overflow-tooltip
                width="300"
              />
              <el-table-column label="图片" align="center">
                <template slot-scope="scope">
                  <el-image
                    style="width: 40px; height: 10px"
                    :src="scope.row.picPath"
                    :preview-src-list="[scope.row.picPath]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label="发布系统" align="left" prop="terminal" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{$setDictionary('TERMINAL',scope.row.terminal) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="应用端" align="left" prop="terminal" show-overflow-tooltip width="160">
                <template slot-scope="scope">
                  {{scope.row.rotationPositionName }}
                </template>
              </el-table-column>
              <el-table-column
                label="行政区划"
                align="left"
                prop="areaNamePath"
                show-overflow-tooltip
              />
              <el-table-column label="轮播图状态" align="center" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.state==10?"启用":"禁用"}}</template>
              </el-table-column>
              <el-table-column label="添加时间" width="150" align="left">
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | moment }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 15px"
                    size="mini"
                    @click="handleEdit('edit', scope.row.rotationId)"
                  >编辑</el-button>
                  <el-button
                    type="text"
                    style="padding:0px 15px"
                    size="mini"
                    @click="handleDelete(scope.row.rotationId)"
                  >删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/Promote",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      searchName: "",
      areaId: "", // 行政区划
      terminal:'',
      rotationPositionId:'',
      areatreeList: [],
      propsarea: {
        //行政规划的值
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      bannerStateList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "10",
          label: "启用"
        },
        {
          value: "20",
          label: "禁用"
        }
      ],
      PublishingSystemList: [
        {
          value: 1,
          label: "APP"
        },
        {
          value: 2,
          label: "小程序"
        },
        {
          value: 3,
          label: "PC端"
        }
      ],
      state: "", // 轮播图状态
      PublishingSystem: "", // 发布系统
      rotationName: "", //轮播图名称
      sysRotationPositionData:[]
    };
  },
  computed: {},
  created() {
    this.getareatree();
    this.terminalsChange(this.terminal)
    this.getTableHeight();
  },
  methods: {
     terminalsChange(sysRotationPositionTerminal) {
      this.$post('/rotationPosition/selectListByTerminal',{sysRotationPositionTerminal}).then(res => {
          // console.log(res)
          if(res.status == '0') {
            this.sysRotationPositionData = res.data
          }
      })
    },
    getData(pageNum = 1) {
      // console.log(this.state);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.rotationName) {
        params.rotationName = this.rotationName;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.terminal) {
        params.terminal = this.terminal;
      }
      if (this.state) {
        params.state = this.state;
      }
      if (this.rotationPositionId) {
        params.rotationPositionId = this.rotationPositionId;
      }
      this.doFetch({
        url: "/run/rotation/queryRotationList",
        params,
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    // 删除轮播图
    handleDelete(rotationId) {
      this.doDel({
        url: "/run/rotation/delete",
        msg: "是否确认删除？",
        ps: {
          type: "post",
          data: { rotationId }
        }
      });
    },
    // 新增推广
    add(stu) {
      this.$router.push({
        path: "/web/operate/AddPromote",
        query: { stu, id: null }
      });
    },
    handleEdit(stu, id) {
      this.$router.push({
        path: "/web/operate/AddPromote",
        query: { stu, id }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh == 'true' || val.query.refresh) {
          this.getData(-1);
        }
      },
      
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.promoteBox {
  // .terminalsSet {
  //   .el-cascader.el-cascader--small {
  //     .el-input.el-input--small.el-input--suffix {
  //       width: 400px;
  //     }
  //   }
  // }
  .rotationName {
    .itemLabel {
      min-width: 6rem;
    }
  }
  .state {
    .itemLabel {
      min-width: 6rem;
      // padding-right: 50px;
    }
  }
}
</style>
<style lang="less" scoped>
</style>
